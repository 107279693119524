<template>
  <div class="col-md-12">
    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      bg-variant="default"
      align="center"
      style="filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15)); border-radius: 10px;"
    >
      <div class="row d-flex justify-content-end" style="margin-bottom: 20px">
        <button
          type="button"
          class="hidden-button"
          @click="refreshData"
          id="refreshButton"
          >
          <i class="fas fa-sync-alt"></i>
        </button>
        <button
          class="btn btn-tambah-data"
          @click.prevent.stop="showModalAdd = true"
        >
          <i class="fa fa-plus-circle" style="color: #ffffff;"></i>Tambah Data
        </button>
      </div>
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        key-expr="id"
        :columns="columns"
        :show-borders="true"
        :word-wrap-enabled="true"
        :column-auto-width="true"
        :remote-operations="true"
        :column-hiding-enabled="true"
      >
        <DxScrolling
          :use-native="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
        />
        <!--          <DxColumn :width="100" data-field="id" caption="No." />-->
        <DxFilterRow :visible="true" />
        <DxColumn
          data-field="name"
          caption="Nama Badan Kelembagaan"
          sort-order="asc"
          :filter-operations="['contains']"
        />
        <DxColumn data-field="icon" caption="Icon" :allow-filtering="false" />
        <DxColumn :width="100" type="buttons" caption="Aksi">
          <DxButton icon="fa fa-trash" hint="Hapus" :onClick="iconDelete" />
          <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>
    </b-card>
    <!--   Modal Tambah Jenis Perundangan   -->

    <b-modal
      v-model="showModalAdd"
      hide-footer
      centered
      id="modal-tambah-badan"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Tambah Badan Kelembagaan</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="example-input-group-1" label-for="example-input-1">
          <template v-slot:label>
            Nama <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback"
            >Nama harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Icon"
          label-for="example-input-2"
        >
          <b-form-input
            id="example-input-2"
            name="example-input-2"
            v-model="form.icon"
          ></b-form-input>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button class="btn btn-delete mr-8" @click="resetForm()">
            Kembali
          </button>
          <button type="submit" class="btn btn-save">Simpan</button>
        </div>
      </b-form>
    </b-modal>

    <!--   Modal Edit Jenis Perundangan   -->

    <b-modal v-model="showModalEdit" hide-footer centered id="modal-edit-badan">
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Edit Badan Kelembagaan</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group id="edit-input-group-1" label-for="edit-input-1">
          <template v-slot:label>
            Nama <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="edit-input-1"
            name="edit-input-1"
            v-model="$v.formEdit.name.$model"
            :state="validateStateFormEdit('name')"
            aria-describedby="input-edit-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-edit-live-feedback"
            >Nama harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="icon-input-group" label="Icon" label-for="icon-input">
          <b-form-input
            id="icon-input"
            name="icon-input"
            v-model="formEdit.icon"
          ></b-form-input>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button class="btn btn-delete mr-7" @click="resetFormEdit()">
            Batal
          </button>
          <button
            type="submit"
            class="btn btn-save"
            :disabled="$v.formEdit.$invalid"
          >
            Simpan
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxScrolling
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import "whatwg-fetch";
import Swal from "sweetalert2";

let token = "";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    return fetch(
      process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan${params}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});

const dataGridRefKey = "my-data-grid";

const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [validationMixin],
  name: "BadanKelembagaan",
  components: {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxButton,
    DxFilterRow,
    DxScrolling
  },
  data() {
    return {
      showModalAdd: false,
      showModalEdit: false,
      isValid: false,
      form: {
        name: null,
        icon: null
      },
      formEdit: {
        name: null,
        icon: null
      },
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      columns: ["id", "name", "icon"],
      dataModal: {}
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    },
    formEdit: {
      name: {
        required
      }
    }
  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        icon: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-badan");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        name: null,
        icon: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-badan");
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      console.log(id, "ID nih cuy");
      let data = {
        name: this.formEdit.name,
        icon: this.formEdit.icon
      };
      console.log(data);
      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan/${id}`
            ),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    setDataModal(data) {
      this.dataModal(data);
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        id: null,
        icon: this.form.icon,
        name: this.form.name
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan`
            ),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    iconDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan/${id}`
            ),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    iconEdit(e) {
      console.log(e.row.data, e.row.rowIndex);
      const data = e.row.data;
      this.formEdit.name = data.name;
      this.formEdit.icon = data.icon;
      this.formEdit.id = data.id;
      this.setDataModal = data;
      this.$root.$emit("bv::show::modal", "modal-edit-badan", e.row.data);
    }
  }
};
</script>
