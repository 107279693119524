<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line nav-res"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Jenis Perundangan
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Direktorat
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Sub Direktorat
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Badan Kelembagaan
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Provinsi
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="5"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Kabupaten/Kota
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
      <form class="form" v-on:submit="submit($event)">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex" lazy>
            <b-tab active>
              <div class="form-group row">
                <JenisPerundangan />
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <Direktorat />
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <SubDirektorat />
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <BadanKelembagaan />
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <Provinsi />
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <KabupatenKota />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import JenisPerundangan from "./JenisPerundangan";
import BadanKelembagaan from "@/view/pages/admin/pengaturan/BadanKelembagaan";
import Direktorat from "@/view/pages/admin/pengaturan/Direktorat";
import Provinsi from "@/view/pages/admin/pengaturan/Provinsi";
import KabupatenKota from "@/view/pages/admin/pengaturan/KabupatenKota";
import SubDirektorat from "@/view/pages/admin/pengaturan/SubDirektorat";

export default {
  name: "Pengaturan",
  components: {
    SubDirektorat,
    KabupatenKota,
    Provinsi,
    Direktorat,
    BadanKelembagaan,
    JenisPerundangan
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.setActivePreviousTab();

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    /**
     * Reset config
     */
    reset(event) {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    },

    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    /**
     * Submit form
     * @param event
     */
    submit(event) {
      event.preventDefault();
      // save new config to localStorage
      localStorage.setItem("config", JSON.stringify(this.config));
      window.location.reload();
    }
  }
};
</script>

<style scoped>
/*.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 1200px;
  overflow: auto;
}*/
</style>
